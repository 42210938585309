import * as React from 'react';
import styled from 'styled-components';

export const LindeFysikalske: React.FC = () => {
  return (
    <StyledLindeFysikalske>
      <img
        src="https://lindefysikalske.no/templates/linde/img/logo.png"
        alt="Linde Fysikalske Institutt"
      />
    </StyledLindeFysikalske>
  );
};

const StyledLindeFysikalske = styled.div`
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;

    aspect-ratio: 16 / 9;

    @supports not (aspect-ratio: 16 / 9) {
      height: 100px;
    }
  }
`;
