import { transparentize } from 'polished';

const colors = {
  primary: '#0285C9',
  primaryShade: transparentize(0.9, '#002F47'),
  secondary: '#4AC1DF',
  text: '#002F47',
  textShade: transparentize(0.5, '#002F47'),
  footerLink: transparentize(0.4, '#002F47'),
  tableRow: transparentize(0.9, '#002F47'),
  tableRowShade: transparentize(0.98, '#002F47'),
};

const buttons = {
  primary: {
    color: colors.primary,
  },
  secondary: {
    color: colors.secondary,
  },
};

export const theme = { colors, buttons };
