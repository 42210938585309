import * as React from 'react';

export const MailIcon: React.FC = () => {
  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.429199 1.6142L1.21015 0.833252H21.5149L22.2959 1.6142V15.6713L21.5149 16.4523H1.21015L0.429199 15.6713V1.6142ZM1.9911 3.23078V14.8904H20.734V3.23234L11.8467 10.0485H10.894L1.9911 3.23078ZM19.2189 2.39516H3.50615L11.3625 8.43817L19.2189 2.39516Z"
        fill="#0285C9"
      />
    </svg>
  );
};
