import * as React from 'react';

import { Page } from '../layout/Page';
import { PageContent } from '../layout/PageContent';
import { Section } from '../ui/Section';
import { Route } from 'react-router-dom';

export const NoMatch: React.FC = () => {
  return (
    <Status code={404}>
      <Page
        title="Finner ikke side - Din treningspartner"
        description="Finner ikke side"
      >
        <PageContent>
          <Section>
            <h1>Finner ikke det du leter etter</h1>
            <p>Skrev du riktig?</p>
          </Section>
        </PageContent>
      </Page>
    </Status>
  );
};

const Status: React.FC<{ code: number }> = ({ code, children }) => {
  return (
    <Route
      render={({ staticContext }: any) => {
        if (staticContext) {
          staticContext.status = code;
        }
        return children;
      }}
    />
  );
};
