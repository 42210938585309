import * as React from 'react';
import styled from 'styled-components';

import { Section } from './Section';

import { ButtonLink, ButtonOutlineLink } from './Button';
import { ButtonGroup } from './ButtonGroup';
import { useImageLoaded } from '../utils/hooks/useImageLoaded';
import { animated, useSpring } from 'react-spring';

export const CompanyMassage: React.FC = () => {
  return (
    <SectionCompany>
      <Section style={{ padding: '150px 0', zIndex: 2 }} className="section">
        <div className="container">
          <h1>Unner du dine ansatte massasje/fysioterapi?</h1>
          {/*<p>
            Belastningslidelser er en større og større del av bedriftens og de
            ansattes hverdag. <br />
            Enorme kostnader er knyttet til sykefravær og behandling av disse.
          </p>*/}
          <p>
            Kontakt oss for et tilbud på massasjetimer eller fysioterapi til
            dine ansatte.
          </p>
          <ButtonGroup className="col-1 center">
            <ButtonLink
              variant="outline"
              color="primary"
              to="/bedriftsmassasje"
            >
              Les mer
            </ButtonLink>
          </ButtonGroup>
        </div>
      </Section>
      <SectionBackground image="https://cdn.wfd.no/dt_assets/bedriftsmassasje.jpg" />
    </SectionCompany>
  );
};

const SectionCompany = styled.section`
  position: relative;
  background: rgb(0, 0, 0);

  .container {
    z-index: 1;
  }

  .section .container {
    h1,
    p {
      color: white !important;
    }
  }
`;

const SectionBackground: React.FC<{ image: string }> = ({ image }) => {
  const { loaded } = useImageLoaded({
    url: image,
  });
  const imageLoaded = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded ? 0.3 : 0 },
  });
  return (
    <StyledSectionBackground style={imageLoaded}>
      <img alt="bedriftsmassasje" src={image} />
    </StyledSectionBackground>
  );
};

const StyledSectionBackground = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
