import * as React from 'react';

interface IUseImageLoadedArgs {
  url: string;
}

export const useImageLoaded = (args: IUseImageLoadedArgs) => {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const img = React.useRef<any>(null);

  React.useEffect(() => {
    if (!img.current) {
      img.current = document.createElement('img');
      img.current.width = '0px';
      img.current.height = '0px';
    }
  }, []);

  React.useEffect(() => {
    img.current.src = args.url;
    img.current.onload = () => {
      setLoaded(true);
    };
  }, [args.url]);

  return { loaded };
};
