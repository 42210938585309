import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html, body {
        width: 100%;
        height: 100%;
    }
    body {
        padding: 0;
        margin: 0;
        font-family: 'Raleway', sans-serif;
        font-size: 16px;
        overflow-x: hidden;

        &.fixed {
            position: fixed;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 600;
        text-align: center;
        color: ${(props: any) => props.theme.colors.text};
    }

    h1 {
        font-size: 36px;
        @media all and (max-width: 800px) {
            font-size: 1.8em;
        }
    }
    h2 {
        font-size: 28px;

       
    }
    h3 {
        font-size: 26px;
    }
    h4 {
        font-size: 24px;
    }
    h5 {
        font-size: 22px;
    }
    h6 {
        font-size: 20px;
    }

    p {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: center;
        color: ${(props: any) => props.theme.colors.text};
    }
`;
