import * as React from 'react';
import styled from 'styled-components';

export const Pricelist: React.FC = () => {
  return (
    <StyledPricelist>
      <StyledPricelistRow>
        <StyledPricelistColumn className="column">
          <span className="heading">Single pt. time</span>
          <span className="subtext">Enkel pt. time for én person</span>
        </StyledPricelistColumn>
        <StyledPricelistColumn className="price center">
          830,-
        </StyledPricelistColumn>
        <StyledPricelistColumn className="align-right">
          pr. time
        </StyledPricelistColumn>
      </StyledPricelistRow>
      <StyledPricelistRow>
        <StyledPricelistColumn className="column">
          <span className="heading">Duo pt. time</span>
          <span className="subtext">Enkel pt. time for to personer</span>
        </StyledPricelistColumn>
        <StyledPricelistColumn className="price center">
          1 300,-
        </StyledPricelistColumn>
        <StyledPricelistColumn className="align-right">
          pr. time
        </StyledPricelistColumn>
      </StyledPricelistRow>
      <StyledPricelistRow>
        <StyledPricelistColumn className="column">
          <span className="heading">Trio pt. time</span>
          <span className="subtext">Enkel pt. time for tre personer</span>
        </StyledPricelistColumn>
        <StyledPricelistColumn className="price center">
          1 600,-
        </StyledPricelistColumn>
        <StyledPricelistColumn className="align-right">
          pr. time
        </StyledPricelistColumn>
      </StyledPricelistRow>
      <StyledPricelistRow>
        <StyledPricelistColumn className="column">
          <span className="heading">Massasje/muskelterapibehandling</span>
        </StyledPricelistColumn>
        <StyledPricelistColumn className="price center">
          800,- *
        </StyledPricelistColumn>
        <StyledPricelistColumn className="align-right">
          30 min.
        </StyledPricelistColumn>
      </StyledPricelistRow>
      <StyledPricelistRow>
        <StyledPricelistColumn className="column">
          <span className="heading">Massasje/muskelterapibehandling</span>
        </StyledPricelistColumn>
        <StyledPricelistColumn className="price center">
          950,- *
        </StyledPricelistColumn>
        <StyledPricelistColumn className="align-right">
          45 min.
        </StyledPricelistColumn>
      </StyledPricelistRow>
      <StyledPricelistRow>
        <StyledPricelistColumn className="column">
          <span className="heading">Massasje/muskelterapibehandling</span>
        </StyledPricelistColumn>
        <StyledPricelistColumn className="price center">
          1085,- *
        </StyledPricelistColumn>
        <StyledPricelistColumn className="align-right">
          60 min.
        </StyledPricelistColumn>
      </StyledPricelistRow>
      <span style={{ fontSize: '14px', marginTop: '20px' }}>
        * Gjelder ikke Thon Hotel - Skeikampen
      </span>
    </StyledPricelist>
  );
};

const StyledPricelist = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  width: 100%;
`;

const StyledPricelistRow = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 60px 80px;
  width: 100%;
  padding: 20px;
  background: ${({ theme }) => theme.colors.tableRow};
  border-radius: 2px;

  &:nth-of-type(even) {
    background: ${({ theme }) => theme.colors.tableRowShade};
  }

  @media all and (max-width: 690px) {
    grid-template-columns: 1fr;

    .price {
      font-size: 20px;
    }

    .center {
      margin-top: 10px;
      justify-self: start !important;
    }

    .align-right {
      justify-self: start !important;
    }
  }
`;

const StyledPricelistColumn = styled.div`
  font-weight: 600;
  align-self: center;
  color: ${({ theme }) => theme.colors.text};

  &.column {
    display: flex;
    flex-flow: column;
  }

  &.center {
    justify-self: center;
  }

  &.align-right {
    justify-self: flex-end;
  }

  .subtext {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textShade};
  }
`;
