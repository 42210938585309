import * as React from 'react';
import styled from 'styled-components';

import { Page } from '../layout/Page';
import { PageContent } from '../layout/PageContent';
import { Section } from '../ui/Section';

export const CompanyMassage: React.FC = () => {
  return (
    <Page
      title="Bedriftsmassasje - Din treningspartner"
      description="Dintreningspartner er et lite tverrfaglig studio som tilbyr personligtrening, fysioterapi, akupunktur og massasje."
    >
      <PageContent>
        <Section>
          <div className="container">
            <h1>Bedriftsmassasje / Fysioterapi</h1>
            <p>
              Belastningslidelser er en større og større del av bedriftens og de
              ansattes hverdag. Enorme kostnader er knyttet til sykefravær og
              behandling av disse. Ryggsmerter alene bidrar med over 10 % av det
              totale sykefraværet innen muskel- og skjelettlidelser.
              Dintreningspartner gir den enkelte behandling/forbygging av
              belastningslidelser på arbeidsplassen og minimerer med det
              utgiften i forbindelse med sykefravær.
            </p>
            <p>
              Dintreningspartners terapeuter er høyt kvalifiserte med
              bakgrunnskunnskap innen anatomi, fysiologi og
              behandlingsteknikker. Terapeutene gjennomgår regelmessig
              kompetanseøkning og evalueringer for å sikre best mulig
              kvalifikasjoner hos den enkelte.
            </p>

            <p>
              Massasje foregår regelmessig og systematisk til avtalt tid hos
              bedriften og gir derfor et meget godt tiltak i kampen mot
              belastningslidelser.
            </p>

            <h3 style={{ marginTop: '30px' }}>Målsetning</h3>

            <p>
              Å begrense muskel- og skjelettrelaterte plager, redusere
              sykefravær, øke trivselen og gi velvære ved hjelp av manuelle
              behandlingsteknikker utført av kompetente terapeuter. Tiltaket har
              også vist seg å fungere som et konkurransemiddel for våre kunder
              mot andre bedrifter. Våre undersøkelser viser at de fleste ansatte
              har gitt gode tilbakemeldinger på initiativet fra bedrifter som
              prioriterer sine ansattes helse og velvære. Store mengder
              forskning støtter i dag massasjens effekt i både forebyggende og
              behandlende øyemed og er i dag benyttet i så vel privat som
              bedriftsmarkedet. I dagens samfunn der muskel- og skjelettlidelser
              øker i omfang og stress blir en større og større del av hverdagen
              er massasje det i mener er bedriftens beste investering.
            </p>
            <h3 style={{ marginTop: '30px' }}>Gjennomføring</h3>
            <p>
              Dintreningspartner sine terapeuter kommer på bedriften til
              forhåndsavtalt tidspunkt og gjennomfører forebyggende og
              behandlende tjenester på de ansatte. Alt utstyr stilles til
              rådighet av Dintreningspartner og ingen merkostnader påløper
              bedriften. Tiltakene gjennomføres etter eksisterende helselover.
              Alle terapeuter er underlagt taushetsplikt og innehar
              ansvarsforsikringer for utførelse av tiltakene. Varighet på
              konsultasjonen er vanligvis 30 minutter, men kan tilpasses etter
              behov og ønsker.
            </p>
            <h3 style={{ marginTop: '30px' }}>
              Trening på arbeidsplassen: gruppe eller individbasert?
            </h3>
            <p>
              Trening på arbeidsplassen kan i noen tilfeller være eneste
              muligheten individet får til aktivitet. Dintreningspartner har
              muligheten til å tilby trening på arbeidsplassen. Dette kan
              gjennomføres i bedriftens egne lokaler eller i treningsrom
              tilknyttet bedriften. Treningsmetoder avhenger av utstyr
              tilgjengelig.
            </p>

            <h3 style={{ marginTop: '30px' }}>Målsetning</h3>
            <p>
              Å øke det fysiske aktivitetsnivået hos den enkelte for å være med
              i forebyggingen og behandlingen av livsstils sykdommer og
              belastningslidelser, samt å tilby aktivitet til de som av en eller
              annen anledning ikke har mulighet til å prioritere dette.
            </p>

            <h3 style={{ marginTop: '30px' }}>Gjennomføring</h3>
            <p>
              Dintreningspartners Personlige Trenere gjennomfører trening i
              henhold til avtalespesifikasjoner. Trenere kan gjennomføre gruppe-
              og individuelle treninger med varierende innhold basert på ønske.
              Treningen kan ha spesielle målsetninger som for eksempel:
              ryggtrening, stabiliseringstrening, kondisjons- eller
              styrketrening. Tiltaket bygges avhengig av bedriftens ønsker og
              behov.
            </p>

            <h3 style={{ marginTop: '30px' }}>Kostholdsveiledning</h3>
            <p>
              På lik linje som en bil trenger riktig drivstoff trenger også
              kroppen riktig næring for å fungere optimalt. At de fleste kan
              forbedre vårt kosthold er nok alle relativt enig i. Hvor mye
              kostholdet påvirker den enkeltes helse og prestasjon er nok
              allikevel ukjent for de fleste. Vi er av den oppfatning at en
              maskin uten riktig drivstoff ikke går som den skal. Kroppen er
              intet unntak.
            </p>

            <h3 style={{ marginTop: '30px' }}>Målsetning</h3>

            <p>
              Å tilby en grunnleggende gjennomgang av hvordan kostholdet
              påvirker vår helse og prestasjon. Kunne skreddersy kostholdsplaner
              og hjelpe til med enten vektnedgang eller vektoppgang. Ved å ta
              vektanalyse har man et godt verktøy til hvert enkelt individ.
              Måling av fettprosent, muskelmasse biologisk alder osv.
            </p>
          </div>
        </Section>
        <Section>
          <div className="container">
            <h1>Priser</h1>
            <p>Kontakt oss for priser.</p>
          </div>
        </Section>
      </PageContent>
    </Page>
  );
};
