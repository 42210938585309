import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring, useSprings, useTrail } from 'react-spring';
import { NavLink } from 'react-router-dom';

export const Menu: React.FC<{ open: boolean; onClick: () => void }> = ({
  open,
  onClick,
}) => {
  const items = [
    {
      id: 'menu_1',
      label: 'Thon Hotel Skeikampen',
      to: '/thon-hotel-skeikampen',
      opacity: 0,
    },
    {
      id: 'menu_2',
      label: 'Linde Fysikalske Institutt',
      to: 'https://lindefysikalske.no/',
      type: 'anchor',
      opacity: 0,
    },
    {
      id: 'menu_3',
      label: 'Avancia Kjelsås',
      to: 'https://avanciakjelsas.no/',
      type: 'anchor',
      opacity: 0,
    },
    {
      id: 'menu_4',
      label: 'Bedriftsmassasje',
      to: '/bedriftsmassasje',
      opacity: 0,
    },
    {
      id: 'menu_5',
      label: 'Om oss',
      to: '/om-oss',
      opacity: 0,
    },

    {
      id: 'menu_6',
      label: 'Personvernerklæring',
      to: '/personvern',
      opacity: 0,
    },
  ];

  const props = useSpring({
    immediate: !open,
    from: { opacity: 0, pointerEvents: 'none' },
    to: { opacity: open ? 1 : 0, pointerEvents: open ? 'auto' : 'none' },
  });

  const [trail, set] = useTrail<{ opacity: number }>(items.length, () => ({
    opacity: 0,
  }));

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        set({ opacity: 1 });
      }, 500);
    } else {
      set({ opacity: 0 });
    }
  }, [open]);

  return (
    <StyledMenu style={props}>
      <div className="container">
        <ul>
          {trail.map((p, index) => {
            const item = items[index];
            if (item.type === 'anchor') {
              return (
                <animated.li key={item.id} style={p}>
                  <StyledMenuAnchor target="_blank" href={item.to}>
                    {item.label}
                  </StyledMenuAnchor>
                </animated.li>
              );
            }

            return (
              <animated.li key={item.id} style={p}>
                <StyledMenuItem exact to={item.to} onClick={onClick}>
                  {item.label}
                </StyledMenuItem>
              </animated.li>
            );
          })}
        </ul>
      </div>
    </StyledMenu>
  );
};

const StyledMenu = styled(animated.div)`
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 150px 0;
  background: rgb(255, 255, 255);
  z-index: 1000;

  .container {
    display: grid;
    grid-row-gap: 30px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    text-align: center;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledMenuItem = styled(NavLink)`
  font-size: 20px;
  font-weight: 600;
  align-self: center;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  text-decoration: none;
`;

const StyledMenuAnchor = styled.a`
  font-size: 20px;
  font-weight: 600;
  align-self: center;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  text-decoration: none;
`;
