import * as React from 'react';
import styled from 'styled-components';

import { Page } from '../layout/Page';
import { PageContent } from '../layout/PageContent';
import { Section } from '../ui/Section';

export const ThonHotels: React.FC = () => {
  return (
    <Page
      title="Thon Hotel Skeikampen - Behandlinsmeny - Din treningspartner"
      description="Se våre behandlinger på Thon Hotel Skeikampen"
    >
      <PageContent>
        <Section>
          <div className="container">
            <h1>Behandlingsmeny - Thon Hotel Skeikampen</h1>
            <p>Alle priser er inkl. mva.</p>
          </div>
          <div className="container">
            <List>
              <li style={{ textAlign: 'center' }}>
                <h4>Klassisk Massasje</h4>
                <p>40 min - 895,-</p>
                <p>60 min - 1095,-</p>
                <p>80 min - 1495,-</p>
                <h6 style={{ marginTop: '30px', marginBottom: '0px' }}>
                  Beskrivelse
                </h6>
                <br /> Klassisk massasje er en fullkroppsbehandling som øker
                blodsirkulasjonen og jobber med muskelspenninger . Kunden kan
                forvente medium trykk med flyt i behandlingen.
              </li>
              <li style={{ textAlign: 'center' }}>
                <h4>Dyp Massasje</h4>
                <p>40 min - 995,-</p>
                <p>60 min - 1195,-</p>
                <p>80 min - 1595,-</p>
                <h6 style={{ marginTop: '30px', marginBottom: '0px' }}>
                  Beskrivelse
                </h6>
                <br /> Dypvevsbehandling er en kraftig behandling som går dypt i
                muskulaturen med trykk på triggerpunkter. Terapeuten tilpasser
                trykk og hvilke punkter som kunden har behov for.
              </li>
              <li style={{ textAlign: 'center' }}>
                <h4>Rygg / nakke massasje</h4>
                <p>30 min - 795,-</p>
                <h6 style={{ marginTop: '30px', marginBottom: '0px' }}>
                  Beskrivelse
                </h6>
                <br />
                Fokuserer på typiske punkter i rygg og nakke..
              </li>
              <li style={{ textAlign: 'center' }}>
                <h4>Idrettsmassasje</h4>
                <p>40 min - 995,-</p>
                <p>60 min - 1195,-</p>
                <p>80 min - 1595,-</p>
                <h6 style={{ marginTop: '30px', marginBottom: '0px' }}>
                  Beskrivelse
                </h6>
                <br />
                Idrettsmassasje bidrar til økt restitusjon mellom treningsøkter.
                Denne behandlingen tilpasses til kunden utifra deres
                treningsopplegg før og etter behandlingen.
              </li>
              <li style={{ textAlign: 'center' }}>
                <h4>Massasje med varme stener</h4>
                <p>80 min - 1495,-</p>
                <h6 style={{ marginTop: '30px', marginBottom: '0px' }}>
                  Beskrivelse
                </h6>
                <br />
                Massasje med varme stener er en avslappende behandling med olje
                og varme lavastener. Varmen og trykket fra stenene kan løse opp
                i spent muskulatur og virker beroligende på kropp og sinn.
              </li>
            </List>
          </div>
        </Section>
      </PageContent>
    </Page>
  );
};

const List = styled.ul`
  li {
    text-align: left !important;
    margin-bottom: 30px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
