import * as React from 'react';
import styled from 'styled-components';

import { Section } from '../ui/Section';
import { Pricelist } from '../ui/Pricelist';

import { Top } from '../layout/Top';
import { CompanyMassage } from '../ui/CompanyMassage';
import { ThonHotels } from '../ui/ThonHotels';
import { Page } from '../layout/Page';

export const Home: React.FC = () => {
  return (
    <Page
      title="Litt mer personlig - Din treningspartner"
      description="Dintreningspartner er et lite tverrfaglig studio som tilbyr personligtrening, fysioterapi, akupunktur og massasje."
    >
      <Top />
      <Section id="about-us">
        <div className="container">
          <h1>Hvem er vi?</h1>
          <p>
            Din treningspartner er et tverrfaglig studio som tilbyr{' '}
            <b>personligtrening</b>, <b>fysioterapi</b> og <b>massasje</b>.{' '}
            <br />
            Du finner oss på Avancia Kjelsås, Linde Fysikalske Institutt og Thon
            Hotel Skeikampen spa.
          </p>
        </div>
      </Section>
      <Section id="offer" style={{ padding: '20px 0 150px 0' }}>
        <div className="container">
          <h1>Hva tilbyr vi?</h1>
          <List>
            <div className="item">Personlig og ivaretatt oppfølging</div>
            <div className="item">Styrke og kondisjonstrening</div>
            <div className="item">Opptrening/rehablitering</div>
            <div className="item">Diett/kostholdsveiledning</div>
            <div className="item">Fysioterapi</div>
            <div className="item">Terapeutisk stretching</div>
          </List>
        </div>
      </Section>
      <ThonHotels />
      <CompanyMassage />
      <Section>
        <div className="container">
          <h1>Priser</h1>
          <p>
            Vi tar disse prisene for våre tjenester/behandlinger.{' '}
            <b>Alle priser er inkl. mva. Andre priser gjelder for bedrifter.</b>
          </p>
          <Pricelist />
        </div>
      </Section>
    </Page>
  );
};

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media all and (max-width: 500px) {
    display: grid;
  }

  .item {
    display: inline-block;
    position: relative;
    padding: 5px;
    font-weight: 600;

    &::before {
      content: '';
      display: inline-block;
      margin-right: 10px;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`;
