import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 50px 0;
  width: 100%;

  &.light {
    background: #000;
  }

  .container {
    width: 100%;
    max-width: 960px;
    padding: 0 30px;
    margin: 0 auto;

    &.grid {
      display: grid;
    }

    &.center {
      justify-content: center;
    }

    &.flex-columns {
      display: grid;
      grid-template-columns: 150px 150px 150px 150px;
      justify-content: center;
      gap: 20px;

      @media all and (max-width: 825px) {
        grid-template-columns: 150px;
        grid-column-gap: 0;
        grid-row-gap: 20px;
      }
    }

    &.small-columns-3 {
      grid-template-columns: 150px 150px 150px;
      grid-column-gap: 20px;

      @media all and (max-width: 825px) {
        grid-template-columns: 150px;
        grid-column-gap: 0;
        grid-row-gap: 20px;
      }
    }

    &.col-1 {
      grid-template-columns: 1fr;
    }
    &.col-3 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px;

      @media all and (max-width: 825px) {
        max-width: 350px;
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 20px;
      }
    }
  }
`;
