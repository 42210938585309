import * as React from 'react';
import styled from 'styled-components';

import { Page } from '../layout/Page';
import { PageContent } from '../layout/PageContent';
import { Section } from '../ui/Section';

export const About: React.FC = () => {
  return (
    <Page title="Om oss - Din treningspartner" description="Litt om oss">
      <PageContent>
        <Section>
          <div className="container">
            <h1>Om oss</h1>
          </div>
          <div className="container">
            <AboutWrapper>
              <AboutItem>
                <AboutImage url="https://cdn.wfd.no/dt_assets/andreas.jpeg" />
                <h2>Andreas Stevning</h2>
                <p>
                  Massasje terapeut (MNMF), Fysioterapeut
                  <br />
                  Mastergradstudent Idrettsfysioterapi
                  <br />
                  Arbeider på TheWell Spa
                </p>
              </AboutItem>
              <AboutItem>
                <AboutImage url="https://cdn.wfd.no/dt_assets/egil.jpeg" />
                <h2>Egil Knapper</h2>
                <p>
                  Muskelterapeut Ifh
                  <br />
                  Ortopedisk terapeut
                  <br />
                  Personligtrener
                  <br />
                  Kostholdsveileder afpt
                </p>
                <p>Gründer og eier av Dintreningspartner</p>
              </AboutItem>
            </AboutWrapper>
          </div>
        </Section>
      </PageContent>
    </Page>
  );
};

const AboutWrapper = styled.div`
  display: grid;
  margin-top: 30px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  width: 100%;

  @media all and (max-width: 550px) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
`;

const AboutItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 20px;
  }
`;

const AboutImage: React.FC<{ url: string; className?: string }> = ({
  url,
  className,
}) => {
  return (
    <AboutImageWrapper className={className}>
      <img alt="bilde av ansatt" src={url} />
    </AboutImageWrapper>
  );
};

const AboutImageWrapper = styled.div`
  margin-bottom: 20px;
  width: 170px;
  height: 170px;
  border-radius: 100%;
  overflow: hidden;
  background-clip: border-box;
  background: rgb(40, 40, 40);

  &.zoom {
    img {
      object-fit: 120%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
