import * as React from 'react';
import styled from 'styled-components';

interface IHamburger {
  open: boolean;
  onClick: () => void;
}

export const Hamburger: React.FC<IHamburger> = ({ open, onClick }) => {
  return (
    <StyledHamburger className={open ? 'open' : ''} onClick={onClick}>
      <span className="top"></span>
      <span className="middle"></span>
      <span className="bottom"></span>
    </StyledHamburger>
  );
};

const StyledHamburger = styled.div`
  position: relative;
  width: 28px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }

  span {
    transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;
    position: absolute;
    display: block;
    width: 28px;
    height: 3px;
    background: ${({ theme }) => theme.colors.text};

    &.top {
      top: 0;
      bottom: 16px;
      margin: auto;
    }
    &.middle {
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &.bottom {
      top: 16px;
      bottom: 0;
      margin: auto;
    }
  }

  &.open {
    .top {
      transform: rotate(45deg);
      bottom: 0;
    }
    .middle {
      opacity: 0;
    }
    .bottom {
      transform: rotate(-45deg);
      top: 0;
    }
  }
`;
