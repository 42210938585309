import * as React from 'react';
import styled from 'styled-components';

import { Section } from './Section';

import { ButtonLink, ButtonOutlineLink } from './Button';
import { ButtonGroup } from './ButtonGroup';
import { useImageLoaded } from '../utils/hooks/useImageLoaded';
import { animated, useSpring } from 'react-spring';

export const ThonHotels: React.FC = () => {
  return (
    <SectionCompany>
      <Section style={{ padding: '150px 0', zIndex: 2 }} className="section">
        <div className="container">
          <h1>Thon Hotel Skeikampen</h1>
          <p>Se vårt behandlingstilbud</p>

          <ButtonGroup className="col-1 center">
            <ButtonLink
              variant="solid"
              color="primary"
              to="/thon-hotel-skeikampen"
            >
              Se behandlingsmeny
            </ButtonLink>
          </ButtonGroup>
        </div>
      </Section>
      <SectionBackground
        image="https://cdn.wfd.no/dt_assets/thon.jpg"
        opacity={0.5}
      />
    </SectionCompany>
  );
};

const SectionCompany = styled.section`
  position: relative;
  background: rgb(0, 0, 0);

  .container {
    z-index: 1;
  }

  .section .container {
    h1,
    p {
      color: white !important;
    }
  }
`;

const SectionBackground: React.FC<{ image: string; opacity?: number }> = ({
  image,
  opacity,
}) => {
  const { loaded } = useImageLoaded({
    url: image,
  });
  const imageLoaded = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded ? (opacity ? opacity : 0.3) : 0 },
  });
  return (
    <StyledSectionBackground style={imageLoaded}>
      <img alt="bedriftsmassasje" src={image} />
    </StyledSectionBackground>
  );
};

const StyledSectionBackground = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
