import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Logo: React.FC = () => {
  return (
    <StyledLogoWrapper to="/">
      <img alt="logo" src="https://cdn.wfd.no/dt_assets/logo.jpg" />
    </StyledLogoWrapper>
  );
};

const StyledLogoWrapper = styled(Link)`
  padding: 10px 0;
  height: 100%;
  
  img {
    height: 100%;
    object-fit: contain;
    object-position: left center;
  }
`;
