import * as React from 'react';
import styled, { ThemeProvider, StyleSheetManager } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { StaticRouter, Route, Switch } from 'react-router';
import { HelmetProvider } from 'react-helmet-async';
import loadable from '@loadable/component';

import { GlobalStyles } from '../client/GlobalStyles';
import { Header } from './layout/Header';
import { Partners } from './ui/Partners';
import { Contact } from './layout/Contact';
import { Footer } from './layout/Footer';

import { Home } from './containers/Home';
import { CompanyMassage } from './containers/CompanyMassage';
import { PrivacyPolicy } from './containers/PrivacyPolicy';
import { NoMatch } from './containers/NoMatch';

import { theme } from '../client/theme';
import { About } from './containers/About';
import { ThonHotels } from './containers/ThonHotels';

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Layout>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/bedriftsmassasje" component={CompanyMassage} />
          <Route exact path="/om-oss" component={About} />
          <Route exact path="/thon-hotel-skeikampen" component={ThonHotels} />
          <Route exact path="/personvern" component={PrivacyPolicy} />
          <Route component={NoMatch} />
        </Switch>
        <Partners />
        <Contact id="contact" />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export const AppClient: React.FC = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  );
};

export const AppServer: React.FC<{
  helmetContext: any;
  req: any;
  sheetInstance: any;
  context: any;
}> = ({ helmetContext, req, sheetInstance, context }) => {
  return (
    <HelmetProvider context={helmetContext}>
      <StyleSheetManager sheet={sheetInstance}>
        <StaticRouter location={req.url} context={context}>
          <App />
        </StaticRouter>
      </StyleSheetManager>
    </HelmetProvider>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
