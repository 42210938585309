import * as React from 'react';
import styled from 'styled-components';

import { animated, useSpring } from 'react-spring';

import { useImageLoaded } from '../utils/hooks/useImageLoaded';
import { useGoToElement } from '../utils/hooks/useGoToElement';

import { Button } from '../ui/Button';
import { ButtonGroup } from '../ui/ButtonGroup';

export const Top: React.FC = () => {
  const { goTo } = useGoToElement({
    offset: 100,
  });

  const { loaded } = useImageLoaded({
    url: 'https://cdn.wfd.no/dt_assets/top.jpg',
  });

  const imageLoaded = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded ? 0.7 : 0 },
  });

  const heading = useSpring({
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0px, 0)' },
  });
  const text = useSpring({
    delay: 300,
    from: { opacity: 0, transform: 'translate3d(0, -20px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0px, 0)' },
  });
  const buttons = useSpring({
    delay: 600,
    from: { opacity: 0, transform: 'translate3d(0, -10px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0px, 0)' },
  });

  return (
    <StyledTop>
      <TopContent>
        <animated.h1 style={{ ...heading, lineHeight: '60px' }}>
          Litt mer personlig
        </animated.h1>
        <animated.h3 style={text}>
          Personlig trener & Massør/muskelterapeut
        </animated.h3>
        <ButtonGroup
          style={buttons}
          className="col-2 content-size center mobile-column"
        >
          <Button
            variant="solid"
            color="primary"
            onClick={() => {
              goTo('offer');
            }}
          >
            Hva tilbyr vi
          </Button>
          <Button
            variant="solid"
            color="secondary"
            onClick={() => {
              goTo('contact');
            }}
          >
            Kontakt oss
          </Button>
        </ButtonGroup>
      </TopContent>

      <StyledCurvy>
        <svg
          width="1440"
          height="236"
          viewBox="0 0 1440 236"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M428.763 193C153.525 241.869 187.326 43.5 0.375 99V294H1440V-3.05176e-05C1146.08 0 797.667 127.5 428.763 193Z"
            fill="white"
          />
        </svg>
      </StyledCurvy>
      <StyledBackground style={imageLoaded}>
        <img alt="topp-bildet" src="https://cdn.wfd.no/dt_assets/top.jpg" />
      </StyledBackground>
    </StyledTop>
  );
};

const StyledTop = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 504px;
  height: auto;
  background: rgb(0, 0, 0);
`;

const StyledCurvy = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  transform: translateY(10px);
  pointer-events: none;
  z-index: 1;
  overflow-x: hidden;

  svg {
    width: 105%;
    height: auto;
  }

  @media all and (max-width: 450px) {
    transform: translateY(10px);
  }
`;

const StyledBackground = styled(animated.div)`
  position: relative;
  width: 100%;
  top: 0;
  opacity: 0.7;
  overflow: hidden;

  img {
    width: calc(100% / (9 / 16));
    min-height: 500px;
    max-height: 650px;
    object-fit: cover;

    @media all and (min-width: 800px) {
      width: 100%;
    }
  }
`;

const TopContent = styled.div`
  position: absolute;
  z-index: 2;

  h1 {
    margin-bottom: 0;
    font-size: 60px;
    font-weight: 600;
    color: white;

    @media all and (max-width: 960px) {
      font-size: 45px;
    }
  }
  h3 {
    margin-top: 0;
    font-size: 25px;
    font-weight: 400;
    color: white;

    @media all and (max-width: 960px) {
      font-size: 20px;
    }
  }
`;
