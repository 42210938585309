import * as React from 'react';

export const PhoneIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4829 23.9378C18.2531 23.9372 18.024 23.912 17.7996 23.8626C13.5264 22.9787 9.58972 20.9061 6.44255 17.8835C3.36254 14.8542 1.22639 10.9972 0.292552 6.7793C0.182441 6.25362 0.209466 5.70845 0.371029 5.19623C0.532592 4.68402 0.823254 4.222 1.21505 3.85464L3.94839 1.2238C4.08799 1.09177 4.25388 0.990668 4.4352 0.927086C4.61653 0.863504 4.80923 0.838873 5.00072 0.854801C5.19927 0.875515 5.39087 0.939476 5.56206 1.04218C5.73324 1.14489 5.87984 1.28386 5.99155 1.4493L9.40822 6.49914C9.53635 6.69508 9.5982 6.92697 9.58466 7.16069C9.57113 7.39442 9.48294 7.61762 9.33305 7.79747L7.61789 9.84747C8.30098 11.3593 9.26837 12.7257 10.4674 13.8723C11.6586 15.0605 13.0667 16.0092 14.6152 16.6671L16.7404 14.9793C16.9189 14.8382 17.1361 14.7548 17.3631 14.7402C17.5901 14.7255 17.8162 14.7803 18.0114 14.8973L23.1706 18.232C23.3476 18.3377 23.4984 18.4821 23.6117 18.6544C23.7251 18.8266 23.7979 19.0223 23.8249 19.2268C23.8518 19.4312 23.8322 19.6391 23.7674 19.8348C23.7026 20.0306 23.5943 20.2092 23.4507 20.3571L20.7857 22.9948C20.483 23.296 20.1237 23.5344 19.7285 23.6963C19.3333 23.8581 18.9099 23.9402 18.4829 23.9378ZM4.89139 2.2078L2.15805 4.83863C1.93764 5.04401 1.77449 5.30322 1.68463 5.59077C1.59477 5.87832 1.5813 6.1843 1.64555 6.47864C2.51388 10.4366 4.50989 14.0584 7.39239 16.9063C10.3541 19.75 14.0587 21.6995 18.0797 22.5301C18.3841 22.5938 18.6995 22.5809 18.9976 22.4926C19.2958 22.4044 19.5674 22.2435 19.7881 22.0245L22.4531 19.3868L17.4716 16.1683L15.1892 17.986C15.1018 18.0551 14.9989 18.1019 14.8893 18.1221C14.7797 18.1424 14.6669 18.1355 14.5606 18.1021C12.6655 17.4039 10.9481 16.2955 9.53122 14.8563C8.06571 13.4877 6.92814 11.8059 6.20339 9.9363C6.17272 9.82312 6.17171 9.70395 6.20043 9.59026C6.22915 9.47656 6.28665 9.37218 6.36739 9.28714L8.21239 7.07997L4.89139 2.2078Z"
        fill="#0285C9"
      />
    </svg>
  );
};
