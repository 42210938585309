import * as React from 'react';

interface IUseScrolled {
  offset: number;
}



export const useScrolled = (args: IUseScrolled) => {
  const [scrolled, setScrolled] = React.useState<boolean>(false);


  if (typeof window !== 'undefined') {
    
    const getScrollingElement = () => {
      if (document.scrollingElement) {
        return document.scrollingElement;
      }

      const docElement = document.documentElement;
      const docElementRect = docElement.getBoundingClientRect();
      return {
        scrollHeight: Math.ceil(docElementRect.height),
        scrollTop: Math.abs(docElementRect.top)
      }
    }


    const handleScroll = () => {
      const scrollingElement = getScrollingElement();
      if (scrollingElement.scrollTop > args.offset && scrolled === false) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    React.useLayoutEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  }

  return { scrolled };
};
