import * as React from 'react';

interface IArgs {
    noScroll?: boolean;
}

export const useBodyNoScroll = (args: IArgs) => {
    const [noScroll, setNoScroll] = React.useState<boolean>(false);

    const handleSetNoScroll = React.useCallback(() => {
        setNoScroll(true);
    }, [setNoScroll]);

    const handleSetScroll = React.useCallback(() => {
        setNoScroll(false);
    }, [setNoScroll]);

    const toggleNoScroll = React.useCallback(() => {
        setNoScroll(prev => !prev);
    }, [setNoScroll]);

    React.useEffect(() => {
        if(typeof args.noScroll === "boolean") {
            if(args.noScroll) {
                setNoScroll(true);
            } else {
                setNoScroll(false);
            }
        }
    }, [args.noScroll]);


    React.useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        if (body && noScroll) {
          body.classList.add('fixed');
        } else {
          body.classList.remove('fixed');
        }
      }, [noScroll]);


    return { handleSetNoScroll, handleSetScroll, toggleNoScroll };
}