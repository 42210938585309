import styled from 'styled-components';
import { animated } from 'react-spring';

export const ButtonGroup = styled(animated.div)`
  margin-top: 20px;
  display: grid;
  width: 100%;

  &.center {
    justify-content: center;
  }

  &.col-1 {
    grid-template-columns: max-content;
  }

  &.col-2 {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    &.content-size {
      grid-template-columns: max-content max-content;
    }
  }

@media all and (max-width: 350px) {
  &.mobile-column {
    grid-template-columns: max-content !important;
    grid-row-gap: 20px;
  }
}
  
`;
