import * as React from 'react';
import * as ReactDOM from 'react-dom';
//import { loadableReady } from '@loadable/component';

import { AppClient } from '../common/App';

ReactDOM.hydrate(<AppClient />, document.getElementById('root'));
/*loadableReady(() => {

    
});*/

