import * as React from 'react';
import styled from 'styled-components';

import { Section } from './Section';
import { Thon } from './logoes/Thon';
import { Wurth } from './logoes/Wurth';
import { Ringnes } from './logoes/Ringnes';
import { LindeFysikalske } from './logoes/LindeFysikalske';

export const Partners: React.FC = () => {
  return (
    <Section className="light">
      <h3 style={{ marginBottom: '20px', color: '#fff' }}>
        Samarbeidspartnere
      </h3>
      <div className="container flex-columns">
        <StyledPartnerItem className="thon">
          <Thon />
        </StyledPartnerItem>
        <StyledPartnerItem className="wurth">
          <Wurth />
        </StyledPartnerItem>
        <StyledPartnerItem className="ringnes">
          <Ringnes />
        </StyledPartnerItem>
        <StyledPartnerItem className="linde">
          <LindeFysikalske />
        </StyledPartnerItem>
      </div>
    </Section>
  );
};

const StyledPartnerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  padding: 20px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);

  &.thon {
    svg {
      width: 100%;
    }
  }
  &.wurth {
    svg {
      width: 100%;
    }
  }
  &.ringnes {
    svg {
      width: 100%;
    }
  }
`;
