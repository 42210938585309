import * as React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { animated, useSpring } from 'react-spring';
import { useScrolled } from '../utils/hooks/useScrolled';

interface IPage {
  title: string;
  description: string;
}

export const Page: React.FC<IPage> = ({ title, description, children }) => {
  const { scrolled } = useScrolled({ offset: 50 });

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scroll(0, 0);
    }
  }, []);

  const props = useSpring({
    immediate: true,
    from: { paddingTop: '70px' },
    to: { paddingTop: scrolled ? '50px' : '70px' },
  });

  return (
    <StyledPage style={props}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}></meta>
      </Helmet>
      {children}
    </StyledPage>
  );
};

const StyledPage = styled(animated.div)`
  width: 100%;
`;
