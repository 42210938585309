import * as React from 'react';

interface IUseGoToElement {
  offset?: number;
}

export const useGoToElement = (args: IUseGoToElement) => {
  const goTo = (id: string) => {
    const element = document.getElementById(id);
    if (element && args.offset) {
      let { top } = element.getBoundingClientRect();
      window.scroll({
        top: top - args.offset || 0,
        behavior: 'smooth',
      });
    }
  };

  return { goTo };
};
