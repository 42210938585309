import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { Logo } from '../ui/Logo';
import { Menu } from '../layout/Menu';
import { Hamburger } from '../ui/Hamburger';

import { useScrolled } from '../utils/hooks/useScrolled';
import { useBodyNoScroll } from '../utils/hooks/useBodyNoScroll';

export const Header: React.FC = () => {
  const { scrolled } = useScrolled({ offset: 50 });
  const [show, setShow] = React.useState(false);

  useBodyNoScroll({
    noScroll: show
  });

  const headerProps = useSpring({
    from: { height: '70px' },
    to: {
      height: scrolled ? '50px' : '70px',
    },
  });

  const headerPropsMenu = useSpring({
    from: { boxShadow: '0 0 10px rgba(0, 0, 0, 0)' },
    to: {
      boxShadow: show
        ? '0 0 10px rgba(0, 0, 0, 0)'
        : '0 0 10px rgba(0, 0, 0, 0.1)',
    },
  });

  return (
    <>
      <StyledHeader style={{ ...headerProps, ...headerPropsMenu }}>
        <Logo />
        <Hamburger open={show} onClick={() => setShow((prev) => !prev)} />
      </StyledHeader>
      <Menu
        open={show}
        onClick={() => {
          setShow(false);
        }}
      />
    </>
  );
};

const StyledHeader = styled(animated.header)`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  background: rgb(255, 255, 255);
  z-index: 1001;
`;
