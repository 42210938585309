import * as React from 'react';
import styled from 'styled-components';

import { Page } from '../layout/Page';
import { PageContent } from '../layout/PageContent';
import { Section } from '../ui/Section';

export const PrivacyPolicy: React.FC = () => {
  return (
    <Page
      title="Personvern - Din treningspartner"
      description="Vårt"
    >
      <PageContent>
        <Section>
          <div className="container">
            <h1>Personvernerklæring</h1>
            <br />
            <h6>Informasjon vi samler inn om deg</h6>
            <p>Vi behandler og oppbevarer ikke persondata fra denne siden.</p>
            <br />
            <h6>Cookies / informasjonskapsler</h6>
            <p>Vi bruker ingen cookies / informasjonskapsler på denne siden.</p>
          </div>
        </Section>
      </PageContent>
    </Page>
  );
};
