import * as React from 'react';

export const Wurth: React.FC = () => {
  return (
    <svg version="1.1" id="Neu_1" x="0px" y="0px" viewBox="0 0 170.1 36.4">
      <path
        id="Wortmarke_pos"
        d="M118.5,30.4h7.3l-5.2-9c2.9-1.2,4.5-3.6,4.5-6.6c0-4.6-3-7.6-9-7.6h-11.5v23.2h6.3v-8.1h3.2L118.5,30.4
	L118.5,30.4z M118.7,14.8c0,1.6-1.1,2.8-3.5,2.8h-4.4V12h4.4C117.6,12,118.7,13.2,118.7,14.8L118.7,14.8L118.7,14.8z M93.3,13.7v7.4
	c0,3.4-1.6,4.5-3.8,4.5c-2.1,0-3.8-1.2-3.8-4.5v-7.5h-6.4v7.8c0,6.1,3.4,9.5,10.3,9.5c6.9,0,10.2-3.4,10.2-9.4v-7.8H93.3L93.3,13.7z
	 M85.7,11.6V7.2h-6.4v4.4H85.7L85.7,11.6z M93.3,11.6h6.4V7.2h-6.4V11.6L93.3,11.6z M52.7,22.6L49.4,7.2H43l5.9,23.2h6.8l4-15.2
	l4,15.2h6.8l5.9-23.2H70l-3.3,15.4l-4-15.4h-6L52.7,22.6L52.7,22.6z M163.6,15.5h-7.8V7.2h-6.4v23.2h6.4v-9.7h7.8v9.7h6.5V7.2h-6.5
	V15.5L163.6,15.5z M133.4,12.3v18.2h6.4V12.3h6V7.2h-18.4v5.1H133.4L133.4,12.3z"
      />
      <path
        d="M33.3,14.3H0V0h13.9v5.6h5.6V0h13.9V14.3L33.3,14.3z M19.4,30.9v5.6c8-1.5,13.9-8.4,13.9-16.2v-0.3H0v0.3
	C0,28,5.9,34.9,13.9,36.4v-5.6H19.4L19.4,30.9z"
      />
    </svg>
  );
};
