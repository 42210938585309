import * as React from 'react';
import styled from 'styled-components';

import { MailIcon } from '../ui/icons/Mail';
import { PhoneIcon } from '../ui/icons/Phone';

export const Contact: React.FC<{ id: any }> = ({ id }) => {
  return (
    <StyledContact id={id}>
      <h1>Kontakt oss</h1>
      <p>
        Ring eller send en epost om du har noen spørsmål, eller om du ønsker en
        time.
      </p>
      <StyledContactWrapper>
        <StyledContactItem>
          <div className="name">Egil Knapper</div>
          <div className="email">
            <MailIcon />
            <a href="mailto:egil@dintreningspartner.no">
              egil@dintreningspartner.no
            </a>
          </div>
          <div className="phone">
            <PhoneIcon />
            <a href="tel:92432303">924 32 303</a>
          </div>
        </StyledContactItem>
        <StyledContactItem>
          <div className="name">Andreas Stevning</div>
          <div className="email">
            <MailIcon />
            <a href="mailto:andreas@dintreningspartner.no">
              andreas@dintreningspartner.no
            </a>
          </div>
          <div className="phone">
            <PhoneIcon />
            <a href="tel:97976653">979 76 653</a>
          </div>
        </StyledContactItem>
      </StyledContactWrapper>
    </StyledContact>
  );
};

const StyledContact = styled.footer`
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding: 50px 30px;
  background: ${({ theme }) => theme.colors.primary};

  h1 {
    color: #fff;
  }
  p {
    color: #fff;
    text-align: justify;
  }
`;

const StyledContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;

  @media all and (max-width: 680px) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
`;

const StyledContactItem = styled.div`
  text-align: left;
  margin-top: 20px;
  color: #fff;

  .email,
  .phone {
    display: flex;
    align-items: center;

    svg {
      path {
        fill: #fff;
      }
    }

    a {
      margin-left: 10px;
    }
  }

  .name {
    font-size: 18px;
    font-weight: 600;
  }
  .email {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .phone {
    padding: 0px;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
`;
