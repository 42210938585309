import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

type ButtonVariant = 'solid' | 'outline';
type ButtonColor = 'primary' | 'secondary';

interface IButton {
  type?: HTMLButtonElement['type'];
  name?: HTMLButtonElement['name'];
  variant: ButtonVariant;
  color: ButtonColor;
  onClick: () => void;
}

interface IButtonLink {
  variant: ButtonVariant;
  color: ButtonColor;
  to: string;
}

export const Button: React.FC<IButton> = ({
  color,
  name,
  children,
  onClick,
}) => {
  return (
    <SolidButton name={name} color={color} onClick={onClick}>
      {children}
    </SolidButton>
  );
};

export const ButtonLink: React.FC<IButtonLink> = ({ to, color, children }) => {
  return (
    <SolidButtonLink to={to} color={color}>
      {children}
    </SolidButtonLink>
  );
};

export const ButtonOutlineLink: React.FC<IButtonLink> = ({
  to,
  color,
  children,
}) => {
  return (
    <OutlineButtonLink to={to} color={color}>
      {children}
    </OutlineButtonLink>
  );
};

const BaseButton = css<{ color: string }>`
  transition: box-shadow 0.15s ease-in-out;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  border-radius: 2px;
  border: none;
  outline: none;
  appearance: none;
  box-shadow: 0 0 0 2px transparent;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
  }
`;

const SolidButtonLink = styled(Link)`
  ${BaseButton}
  background: ${({ theme, color }) => theme.buttons[color].color};

  text-decoration: none;
`;

const SolidButton = styled.button`
  ${BaseButton}
  background: ${({ theme, color }) => theme.buttons[color].color};
`;

const OutlineButtonLink = styled((props) => <Link {...props} />)`
  ${BaseButton}
  text-decoration: none;
  border: solid 2px ${({ theme, color }) => theme.buttons[color].color};
`;
