import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <span>
        © 2021 Din Treningspartner
        <Link to="/personvern">Personvernerklæring</Link>
      </span>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 20px;

  a {
    margin-left: 10px;
  }

  @media all and (max-width: 520px) {
    span {
      display: flex;
      flex-flow: column;
      text-align: center;

      a {
        margin-left: 0;
      }
    }
  }

  span {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text};

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.footerLink};
    }
  }
`;
